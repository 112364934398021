export const Welcome = (props) => {
  return (
    <div id='welcome' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>WELCOME!</h2>
          <p>
          Thanks for stopping by!<br/>
          Vera Fusion helps start-ups and small businesses achieve the online presence they need to be successful.<br/><br/>
          We look forward to start working with you! <br/>
<br/>
          HABLAMOS ESPAÑOL

          </p>
        </div>
        <div id='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-12 col-sm-6 team'>
                  <div className='thumbnail'>
                    {' '}
                    <img src={d.img} alt='Marcos Galizia' className='team-img rounded-circle' />
                    <div className='caption'>
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                      <p><a href='https://www.linkedin.com/in/marcos-galizia-16910315/' target='_blank'><i class='fa fa-linkedin fa-2x'></i></a></p>
                   
                      <br/><br/>
    <h4><a href='https://g.page/r/CTsjV2ViGVDZEAE/review' target='_blank'><b>LEAVE US A REVIEW</b></a></h4>
					<i class="fa-solid fa-star"></i>	<i class="fa-solid fa-star"></i>
          <i class="fa-solid fa-star"></i>	<i class="fa-solid fa-star"></i>	
          <i class="fa-solid fa-star"></i>
             </div>
                   
                    </div>

                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
