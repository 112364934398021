export const Contentwriting = (props) => {
  return (
    <div id="contentwriting">
      <div className="container">
        <div className="row">
      

          <div className="col-xs-12 col-sm-12">
            <div className="about-text">
                <br/>
              <h2>Article Writing Service</h2>

              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Our Seasoned Writers Will Deliver Articles That:</h3>
              <div className="list-style">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                  <br/>
                </div>
           
<div className="col-sm-6 col-xs-12">
  <div align="center"><a href='https://quantumdesigners.com/WHMCS/cart.php?gid=2' target='_blank' rel='noreferrer'
                  className='btn btn-custom btn-lg page-scroll'>
                  QUALITY ARTICLES
                </a>{' '}
 </div> <br/> <br/>
 </div>
<div className="col-sm-6 col-xs-12">
<div align="center">
    <a href='https://quantumdesigners.com/WHMCS/cart.php?gid=2' target='_blank' rel='noreferrer'
                  className='btn btn-custom btn-lg page-scroll'>
                  PREMIUM ARTICLES
                </a>{' '}
                </div>
</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
