import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
//import { Header } from "./components/header";
//import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Welcome } from "./components/Welcome";
import { Contact } from "./components/contact";
import { Blog} from "./components/blog";
//import { Slide } from "./components/slide";
//import Smartslider from "./components/smartslider";
import Carousel from "./components/carousel";
import Collapsible from "./components/belowheader";
import {Contentwriting} from "./components/contentwriting";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import {Adsense} from "@ctrl/react-adsense";
import { Wp } from "./components/wp";
import "./App.css";
import ClearCache from 'react-clear-cache';


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

//changed to function()   const App = () =>
const App = () => {
  const [landingPageData, setLandingPageData
  ] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  },[]);
 

  return (
    <div>
   <Navigation /> 
   <Carousel />
 {/* <div id='googleads'><br/>
  <Adsense client="ca-pub-7797221017399624"
  slot="7139438041" /></div>*/}

   <Welcome data={landingPageData.Welcome} />
   <Wp />
    { /* <Header data={landingPageData.Header} /> */ }
    { /* <Features data={landingPageData.Features} /> */ }
    <Collapsible data={landingPageData.Features} />
 
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Contentwriting data={landingPageData.ContentWriting} />
      <Gallery data={landingPageData.Gallery}/>
      <Testimonials data={landingPageData.Testimonials} />
	    <Blog data={landingPageData.Blog} />
      <Contact data={landingPageData.Contact} />
      <ScrollUpButton />
  
    <ClearCache>
      {({ isLatestVersion, emptyCacheStorage }) => (
        <div>
          {!isLatestVersion && (
            <p>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  emptyCacheStorage();
                }}
              >
                Update version
              </a>
            </p>
          )}
        </div>
      )}
    </ClearCache>
  </div>


  );
};

export default App;
